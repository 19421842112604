export const MAXLENGTH_TEXTAREA = 4000;
export const MAXLENGTH_TEXTFIELD = 200;
export const AUTHENTICATED = 'Microsoft.Ccm/Authenticated';
export const INTERNAL_USER = 'Microsoft.Ccm/InternalUser';
export const WORKFLOW_HISTORY_CREATE = 'Microsoft.Ccm/WorkflowHistory/Create';
export const WORKFLOW_HISTORY_DELETE = 'Microsoft.Ccm/WorkflowHistory/Delete';
export const WORKFLOW_HISTORY_READ = 'Microsoft.Ccm/WorkflowHistory/Read';
export const WORKFLOW_HISTORY_WRITE = 'Microsoft.Ccm/WorkflowHistory/Write';
export const SERVICE_CREATE = 'Microsoft.Ccm/Service/Create';
export const SERVICE_DELETE = 'Microsoft.Ccm/Service/Delete';
export const SERVICE_READ = 'Microsoft.Ccm/Service/Read';
export const SERVICE_WRITE = 'Microsoft.Ccm/Service/Write';
export const EVIDENCE_PACKAGE_CREATE = 'Microsoft.Ccm/EvidencePackage/Create';
export const EVIDENCE_PACKAGE_UPDATE = 'Microsoft.Ccm/EvidencePackage/Update';
export const EVIDENCE_PACKAGE_READ = 'Microsoft.Ccm/EvidencePackage/Read';
export const EVIDENCE_PACKAGE_DOWNLOAD = 'Microsoft.Ccm/EvidencePackage/Download';
export const EVIDENCE_PACKAGE_NOTES_WRITE = 'Microsoft.Ccm/EvidencePackage/Notes/Write';
export const EVIDENCE_PACKAGE_SUBMISSION_WRITE = 'Microsoft.Ccm/EvidencePackage/PackageSubmission/Write';
export const EVIDENCE_PACKAGE_AUDITING_REVIEW_WRITE = 'Microsoft.Ccm/EvidencePackage/AuditingReview/Write';
export const EVIDENCE_PACKAGE_AUDITING_REVIEW_READ = 'Microsoft.Ccm/EvidencePackage/AuditingReview/Read';
export const EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_WRITE = 'Microsoft.Ccm/EvidencePackage/AuthorizingReview/Write';
export const EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_READ = 'Microsoft.Ccm/EvidencePackage/AuthorizingReview/Read';
export const EVIDENCE_PACKAGE_DISCUSSION_WRITE = 'Microsoft.Ccm/EvidencePackage/PackageDiscussion/Write';
export const SNAPSHOT_CREATE = 'Microsoft.Ccm/Snapshot/Create';
export const SNAPSHOT_DELETE = 'Microsoft.Ccm/Snapshot/Delete';
export const SNAPSHOT_READ = 'Microsoft.Ccm/Snapshot/Read';
export const SNAPSHOT_WRITE = 'Microsoft.Ccm/Snapshot/Write';
export const FILE_ATTACHMENT_CREATE = 'Microsoft.Ccm/FileAttachment/Create';
export const FILE_ATTACHMENT_EVIDENCE_PACKAGE_REVIEW_CREATE = 'Microsoft.Ccm/FileAttachment/EvidencePackageReview/Create';
export const FILE_ATTACHMENT_DELETE = 'Microsoft.Ccm/FileAttachment/Delete';
export const FILE_ATTACHMENT_READ = 'Microsoft.Ccm/FileAttachment/Read';
export const FILE_ATTACHMENT_WRITE = 'Microsoft.Ccm/FileAttachment/Write';
export const USER_CREATE = 'Microsoft.Ccm/User/Create';
export const USER_DELETE = 'Microsoft.Ccm/User/Delete';
export const USER_READ = 'Microsoft.Ccm/User/Read';
export const USER_WRITE = 'Microsoft.Ccm/User/Write';
export const CONTROL_READ = 'Microsoft.Ccm/Controls/Read';
export const CONTROL_METADATA_READ = 'Microsoft.Ccm/ControlMetadata/Read';
export const CONTROL_METADATA_WRITE = 'Microsoft.Ccm/ControlMetadata/Write';
export const USERROLE_CREATE = 'Microsoft.Ccm/UserRole/Create';
export const USERROLE_DELETE = 'Microsoft.Ccm/UserRole/Delete';
export const USERROLE_READ = 'Microsoft.Ccm/UserRole/Read';
export const DEVELOPER_OPERATION = 'Microsoft.Ccm/DeveloperOperation';
export const SITE_WIDE_SUBJECT = 'Microsoft.Ccm/SiteWide';
export const AUDITOR_ACTIONS_READ = 'Microsoft.Ccm/AuditorActions/Read';
export const AUTHORIZATION_ACTIONS_READ = 'Microsoft.Ccm/AuthorizingActions/Read';
export const DOD_AUTHORIZATION_ACTIONS_WRITE = 'Microsoft.Ccm/DodAuthorizingActions/Write';
export const DHS_AUTHORIZATION_ACTIONS_WRITE = 'Microsoft.Ccm/DhsAuthorizingActions/Write';
export const GSA_AUTHORIZATION_ACTIONS_WRITE = 'Microsoft.Ccm/GsaAuthorizingActions/Write';
export const COMPLIANCE_TEAM_ACTIONS_READ = 'Microsoft.Ccm/ComplianceTeamActions/Read';
export const SYSTEM_CREATE = 'Microsoft.Ccm/System/Create';
export const SYSTEM_DELETE = 'Microsoft.Ccm/System/Delete';
export const SYSTEM_READ = 'Microsoft.Ccm/System/Read';
export const SYSTEM_WRITE = 'Microsoft.Ccm/System/Write';
export const CONMON_READ = 'Microsoft.Ccm/ContinuousMonitoring/Read';
export const TENANT_GOVERNANCE_READ = 'Microsoft.Ccm/CuratedTenants/ComplianceMonitoring/Read';
export const TENANT_GOVERNANCE_DASHBOARD_READ = 'Microsoft.Ccm/CuratedTenants/ComplianceMonitoring/Dashboard/Read';
export const TENANT_GOVERNANCE_TENANT_READ = 'Microsoft.Ccm/CuratedTenants/ComplianceMonitoring/Dashboard/Tenant/Read';

// role IDs
export const ATOA_OWNER_ID = 'AtoaOwner';
export const ATOA_CONTRIBUTOR_ID = 'AtoaContributor';
export const ATOA_READER_ID = 'AtoaReader';
export const CONMON_CONTRIBUTOR_ID = 'ConMonContributor';

// evidence library
export const EVIDENCE_LIBRARY = 'evidence-library';

// colors
export const colors = {
  orange: '#F7894A',
  blueGreen: '#228286',
};
